#countries {
    padding: 70px 0 200px 0;

    .container {

        .row {
            margin-bottom: -40px;
        }
    }
}

#countries{
    width: 100%;
    overflow-x: hidden;
}

.country-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.country-img {
    width: 200px;
    margin-bottom: 20px;
    border-radius: 10px;
}