#document-terjemah {
    margin-top: -300px;

    .container {
        border-radius: 50px;

        .row {
            user-select: none;
        }
    }
}

#document-terjemah{
width: 100%;
overflow-x: hidden;
}

.document-terjemah-detail {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 200px;
        max-height: 200px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 768px) {
    #document-terjemah {
    
        .container {
            border-radius: 25px;
        }
    }
}