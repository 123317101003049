#purpose-terjemah {
    color: white;
    align-items: center;
    text-align: center;
    padding: 70px 40px 0 40px;
    margin-bottom: -40px;
}

.purpose-name {
    margin-bottom: 40px;
}