#header {
    background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.8)
      ),
      url("https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
    background-size: cover;
    padding: 80px 0 120px 0;
    color: white;
    border-radius: 0 0 50px 50px;
    text-align: center;
  }

.page-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
  #header {
    border-radius: 0 0 25px 25px;
  }
}