#partner {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url("https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
    background-size: cover;
    padding: 70px 0 170px 0;
    color: white;
    text-align: center;
    border-radius: 50px;
    margin-top: 70px;

    .container {
        text-align: center;
    
        .partner-wrap {
            margin-bottom: 30px
        }
    }
}

.partner-left {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.partner-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.partner-name {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 60px 0 20px 0px;

    .partner-img {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 40px;

        .circle-shadow {
            height: 300px;
            width: 300px;
            background-color: rgba(255, 255, 255, 0.4);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .circle-primary {
                height: 220px;
                width: 220px;
                background-color: #EFAB23;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                img {
                    height: 120px;
                }
            }
        }
    }
    
}

@media only screen and (max-width: 768px) {
    #partner {
        border-radius: 25px;
    }

    .partner-left {
        border-right: none;
    }
    
    .partner-bottom {
        border-top: none;
    }
}

@media only screen and (max-width: 1200px) {
    .partner-name {
    
        .partner-img {
    
            .circle-shadow {
                height: 200px;
                width: 200px;
    
                .circle-primary {
                    height: 120px;
                    width: 120px;
        
                    img {
                        height: 70px;
                    }
                }
            }
        }
        
    }
}