#more-articles {
    padding: 70px 0 0px 0;

    .container {

        .row {
            // margin-bottom: -40px;
            text-align: center;
            align-items: center;
        }
    }
}