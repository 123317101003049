#hero {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url("https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260");
  background-size: cover;
  padding: 80px 0 100px 0;
  color: white;
  border-radius: 0 0 50px 50px;
}

.brand-element-img {
  width: 450px;
}

.btn-regular:hover{
  color: #ffffff !important;
}
.btn-regular{
  background-color: #EFAB23;
  color: #ffffff;
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
  width: fit-content;
  color: #000;
  font-size: 1.2rem;
  // line-height: 2;
  border-radius: 5px;
}

.hero-title {
  font-family: "DM Serif Display", serif;
  line-height: 1.5;
  margin-bottom: 40px;
}

.hero-subtitle {
  font-family: "Roboto", sans-serif;
  color: #ffffff;
  text-align:justify;
}

@media only screen and (max-width: 1200px) {
  .brand-element {
    display: none;
  }

  .hero-text {
    align-items: center;
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  #hero {
    border-radius: 0 0 25px 25px;
  }
}