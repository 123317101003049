#form-offer {
    margin-top: -300px;
}

.form-offer {
    padding: 70px;
    border-radius: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
    #form-offer {
        margin-top: 0px;
    }
    
    .form-offer {
        padding: 70px 40px;
        border-radius: 25px;
        margin-top: -70px;
    }
}