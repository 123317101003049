#excellence {
    margin-top: -300px;
    width: 100%;
    overflow-x: hidden;
}

.excellence-card {
    margin-top: 50px;
    background-color: white;
    padding: 40px;
    text-align: center;
    border-radius: 50px;
    font-family: "DM Serif Display", serif;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);

    .card-outline {
        border: 1px solid rgba(0, 0, 0, 0.2);
        padding: 40px 0px;
        border-radius: 50px;

        .card-img-top {
            max-width: 200px;
        }

        .card-body > .card-title {
            font-size: 40px;
            text-align: center;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .excellence-card {
        padding: 20px;
        border-radius: 20px;

        .card-outline {
            padding: 10px;
            border-radius: 30px;

            .card-body > .card-title {
                font-size: 24px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #excellence {
        margin-top: 0px;
    }
}