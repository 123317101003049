#footer {
    margin-top: 70px;
    text-align: left;
    background-color: black;
    color: white;
    border-radius: 50px 50px 0 0;
    padding-bottom: 40px;
}

.footer {
    padding: 70px 40px 0 40px;
}

.contact-phone {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    
    i {
        font-size: 80px;
    }
    
    h4 {
        font-size: 36px;
        margin-left: 10px;
    }
}

.footer-social-media {
    margin-top: 40px;
    padding: 0px;

    li {
        margin-left: 20px;
    }

    li:first-child {
        margin-left: 0px
    }
}

.btn-floating {
    font-size: 40px;
    float: left;
    line-height: 80px;
    color: #2B2D42;
    height: 80px;
    width: 80px;
    text-align: center;
    margin-bottom: 40px;
    background-color: #EFAB23;
    border-radius: 10px;
    cursor: pointer;

    &:hover {
        color: #2B2D42;
        background-color: #D3901A;
    }
}

.footer-link {
    color: white;
    line-height: 2;
    font-size: 18px;
}

.footer-copyright {
    font-size: 24px;
    margin-top: 70px;

    a {
        text-decoration: none;
    }
}

@media only screen and (max-width: 768px) {
    #footer {
        border-radius: 25px 25px 0 0;
    }

    .contact-phone {
        i {
            font-size: 40px;
        }
        
        h4 {
            font-size: 28px;
            margin-left: 10px;
        }
    }

    .footer-social-media {
        li {
            margin-left: 5px;
        }
    
        li:first-child {
            margin-left: 0px
        }
    }

    .btn-floating {
        font-size: 30px;
        line-height: 60px;
        height: 60px;
        width: 60px;
    }

    .footer-copyright {
        font-size: 16px;
    }
}