#article {
    margin-top: -300px;
}

.article-bg {
    border-radius: 50px;
    padding: 70px;
    text-align: justify;
}

.article-img {
    margin-bottom: 40px;
}

.article-info {
    margin-bottom: 40px;
}