nav.navbar {
  padding: 20px 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  user-select: none;
}

.navbar-brand img {
  max-width: 150px;
  width: 150px;
}

.navbar-nav {
  .nav-item {
    margin-left: 50px;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #ffffff;

    .nav-item-link {

      .nav-link {
        color: #ffffff;
      }
    }

    .navbar-link-active {

      .nav-link {
        color: #EFAB23;
        border-bottom: 2px solid #EFAB23;
      }
    }
  }
}

.navbar-toggler {
  margin-right: 40px;
  border: 1px solid #ffffff;
  color: #ffffff;

  .navbar-toggler-icon {
    color: #ffffff !important;
  }
}

@media only screen and (max-width: 912px){
  nav.navbar {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    overflow-x: hidden;
  }
  nav.navbar .nav-item{
    margin-left: 20px;
  }
}

@media only screen and (max-width: 768px) {
  nav.navbar {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    overflow-x: hidden;
  }

  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 3rem !important;
}

  .navbar-brand img {
    max-width: 100px;
    width: 100px;
  }

  nav.navbar .nav-item{
    margin-left: 20px;
  }

  .navbar-collapse {
    
    ul.navbar-nav {
      // margin-top: 30px;

      .navbar-link-active {

        .nav-link {
          border-bottom: none;
        }
      }
    }
  }
}



@media only screen and (max-width: 600px) {
  nav.navbar {
    background-color: rgba(0, 0, 0, 1);
    width: 100%;
    overflow-x: hidden;
  }

  .py-5 {
    padding-top: 1rem !important;
    padding-bottom: 3rem !important;
}

  .navbar-brand img {
    max-width: 100px;
    width: 100px;
  }

  nav.navbar .nav-item{
    margin-left: 20px;
  }

  .navbar-collapse {
    
    ul.navbar-nav {
      margin-top: 30px;

      .navbar-link-active {

        .nav-link {
          border-bottom: none;
        }
      }
    }
  }
}