.blog-featured {
    padding: 70px 70px 70px 70px;
    border-radius: 50px;
}

.blog-card {
    text-align: center;
    // background: rgb(241, 241, 241);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 70px;
    color: #2B2D42;

    .blog-card-body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &:hover > .blog-card-body > .blog-card-title {
        color: #EFAB23;
    }
}

@media only screen and (max-width: 1200px) {

    .blog-featured {
        padding: 70px 40px 30px 40px;
    }

    .blog-card {
        margin-bottom: 40px;

        .blog-card-body {

            .blog-card-title {
                font-size: 24px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .blog-featured {
        border-radius: 25px;
        margin-bottom: -70px;
    }
}