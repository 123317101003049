#instansi {
    padding: 70px 0;
    width: 100%;
    overflow-x: hidden;
}

.instansi-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 40px;
}

.instansi-img {
    width: 200px;
    margin-bottom: 20px;
}


.row-custome{
    padding-right: 0 !important;
    padding-left: 0 !important;
}