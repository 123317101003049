#document-legalisasi {
    width: 100%;
    overflow-x: hidden;
    
    .container {
        border-radius: 50px;
        padding: 70px 0 30px 0;

        .row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            user-select: none;
        }
    }
}

.document-legalisasi-detail {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 20%;

    img {
        height: 100px;
        max-height: 100px;
        margin-bottom: 20px;
    }
}

.dokumen_komersial {
    margin-top: 30px;
}

@media only screen and (max-width: 768px) {
    #document-legalisasi {
        margin-bottom: -70px;
    
        .container {
            border-radius: 25px;
        }
    }

    .document-legalisasi-detail {
        width: 50%;
    }
    .section-title h1 {
        font-size:2rem;
    }
}