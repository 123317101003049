#service {
    text-align: center;

    .link-to {
        color: #212529;

        &:hover {
            color:#EFAB23;
        }
    }
}

#service{
    width: 100%;
    overflow-x: hidden;
}

.service-card > .card-body {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}