#intro {
    margin-top: -300px;
}

.intro-bg {
    border-radius: 50px;
    padding: 70px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .intro-bg {
        border-radius: 25px;
    }
}